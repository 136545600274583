import React from "react"
import { Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import useLiveControl from "./../../controlled/useLiveControl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
import HubspotForm from "react-hubspot-form"

// accordian
const blockElements = {
  animator: "accordion-animator",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const customLink = (url, e) => {
  navigate(url)
}


const WhatIsContractorFinancingPage = () => {

  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"What is Contractor Financing?"}
        description={
          "Contractor financing provides an easy way for your customers to pay for their home improvement projects — and for you to get paid quickly and on time"
        }
      />
      <Helmet>
        <body className="wcf" />
      </Helmet>
      {isStaging ? (
        <section 
          className="banner-area"
          id="home-improvement-financing-banner-section">
          <div className="container banner-container"
              style={{
                        backgroundImage:
                          "url(" +
                          "http://gbdevpress.enerbank.com/wp-content/uploads/2021/09/LKrnT76N.jpeg" +
                          ")",
                          backgroundPosition: "50% 25%",
                      }}
              >
                <div className="relative banner-area-text-container">
                    <div className="container text-center">
                      <div className="identifier-container row-fluid">
                        <div className="element span12">
                            <div className="rhif-identifier span5">
                                <h4>HOME IMPROVEMENT FINANCING</h4>
                            </div>
                            <div className="right-angle span2"></div>
                        </div>
                      </div>
                      <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                        What is contractor financing<br/> and how do I offer it
                      </h1>
                    </div>
                  </div>
            </div>
            <div className="container text-center mobile">
              <h1 className="text-white text-left" style={{ padding: "80 0" }}>
                What is contractor financing and how do I offer it
              </h1>
            </div>
          </section>
        ) : (
        <section
        class="page-hero"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2021/09/LKrnT76N.jpeg" +
            ")",
          backgroundPosition: "50% 25%",
        }}
      >
        <div class="page-hero-inner d-flex justify-content-center align-items-center">
          <div class="container text-center">
            <h1 class="text-white">
              What is Contractor Financing and How to Offer it
            </h1>
          </div>
        </div>
      </section>
      )}
      {isStaging ? (
      <section className="">
        <div className="container">
          <div>
            <p>
              When hiring a contractor for their home renovations, not every
              homeowner has a good idea of how much quality services and
              materials will cost. Or, they don&#39;t have the funds they need
              on hand to pay for their project. This means they may back out
              after the initial bid, choose lower-quality materials, or reduce
              the size of the project to save money.{" "}
              <Link
                to="/contractor-financing/"
                className="text-blue font-weight-bold"
              >
                Contractor financing
              </Link>{" "}
              for customers puts their dream projects within reach, without
              compromises — and will empower you to grow your business and
              increase sales of larger jobs.
            </p>
          </div>
        </div>
      </section>
      ) : (
        <section className="bg-light-gray py-5">
        <div className="container">
          <div>
            <p>
              When hiring a contractor for their home renovations, not every
              homeowner has a good idea of how much quality services and
              materials will cost. Or, they don&#39;t have the funds they need
              on hand to pay for their project. This means they may back out
              after the initial bid, choose lower-quality materials, or reduce
              the size of the project to save money.{" "}
              <Link
                to="/contractor-financing/"
                className="text-blue font-weight-bold"
              >
                Contractor financing
              </Link>{" "}
              for customers puts their dream projects within reach, without
              compromises — and will empower you to grow your business and
              increase sales of larger jobs.
            </p>
          </div>
        </div>
      </section>
      )}
      {isStaging ? (
      <section className="bg-light-gray">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center section-header">
              <h2 className="">
                Get paid on time with contractor financing
              </h2>
              <p className="text-center">
                When pursuing a home imporvement project, customers love
                options. Regions&#39; contractor financing
                offers exactly what your customers are looking for: ease &
                convenience.{" "}
              </p>
            </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7">
                <img className="image" src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/get-paid-on-time-contractor-financing.jpg" />
              </div>
              <div className="col-lg-5">
                <div className="img-container">
                  <h3 className="pb-3">Jump to a Topic</h3>
                  <ul className="fa-ul table-contents">
                    <li className="">
                      <Link
                        to="#overview"
                        className="f"
                      >
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="1" /></span>What is contractor financing?
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="#how-contractors-offer-financing-to-customers"
                        className=""
                      >
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="2" /></span>How do contractors offer financing to their customers?
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="#benefits"
                        className=""
                      >
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="3" /></span>Why is offering contractor financing beneficial?
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="#how-it-works"
                        className=""
                      >
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="4" /></span>How does contractor financing work with Regions?
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="#qualifications-for-contractor-financing"
                        className=""
                      >
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="5" /></span>What are the qualifications to offer financing from Regions?
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="#best-practices"
                        className=""
                      >
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="6" /></span>Best practices for offering customer financing as a contractor
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="#join-a-loan-program"
                        className=""
                      >
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="7" /></span>Join a loan program with Regions
                      </Link>
                    </li>
                    <li className="">
                      <Link to="#faqs" className="">
                      <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="8" /></span>Frequently asked  questions
                      </Link>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      ) : (
        <section className="eb-blue py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center section-header">
              <h2 className="text-white">
                Get Paid on Time with Contractor Financing
              </h2>
              <h3 className="text-white">
                When pursuing a home imporvement project, customers love
                options. Regions | EnerBank USA&#39;s contractor financing
                offers exactly what your customers are looking for: ease &
                convenience.{" "}
              </h3>
            </div>
            <div className="col-lg-4">
              <nav className="short-divider section-links">
                <div className="nav-content">
                  <h3 className="mt-2 mb-4 text-white">Table of Contents</h3>
                  <ol className="wcf-table-of-contents">
                    <li className="mb-3">
                      <Link
                        to="#overview"
                        className="font-weight-bold text-black"
                      >
                        What is Contractor Financing?
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="#how-contractors-offer-financing-to-customers"
                        className="font-weight-bold text-black"
                      >
                        How Do Contractors Offer Financing to Their Customers?
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="#benefits"
                        className="font-weight-bold text-black"
                      >
                        Why is Offering Contractor Financing Beneficial?
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="#how-it-works"
                        className="font-weight-bold text-black"
                      >
                        How Does Contractor Financing Work with Regions |
                        EnerBank USA?
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="#qualifications-for-contractor-financing"
                        className="font-weight-bold text-black"
                      >
                        What Are the Qualifications to Offer Financing from
                        Regions | EnerBank USA?
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="#best-practices"
                        className="font-weight-bold text-black"
                      >
                        Best Practices for Offering Customer Financing as a
                        Contractor
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="#join-a-loan-program"
                        className="font-weight-bold text-black"
                      >
                        Join a Loan Program with Regions | EnerBank USA
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="#faqs" className="font-weight-bold text-black">
                        Frequently Asked Questions
                      </Link>
                    </li>
                  </ol>
                </div>
              </nav>
            </div>
            <div className="col-lg-8">
              <div className="img-container">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/08/wcf-nav-image.png"
                  alt="Get Paid on Time with Contractor Financing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
      {isStaging ? (  
        <section id="overview" className="financing-overview">
          <div className="">
            <div className="container">
              <h2 className="text-center">
                What Is contractor financing?
              </h2>
            </div>
          </div>
          <div className="container">
              <div className="row">
                <span id="join-a-loan-program-now" className=""></span>
                <span id="start-benefitting-now"></span>
                <span id="start-now"></span>
                <span id="start-offering-financing-for-clients"></span>
                <div className="col-md-6">
                  <p>
                    <Link
                      to="/contractor-financing/"
                      className=""
                    >
                      Contractor financing
                    </Link>{" "}
                    provides an easy way for your customers to pay for their home
                    improvement projects — and for you to get paid quickly and on
                    time. There are several types of financing:
                  </p>

                  <ul className="fa-ul eb-green">
                    <li className="">
                      <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                      <span className="font-weight-bold">
                        Business Lines of Credit
                      </span>{" "}
                      — Flexible funding from a bank or credit union. It can be
                      tapped at any time for the amount needed but has a set
                      limit. You only repay interest on outstanding balances.
                    </li>
                    <li className="">
                    <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                      <span className="font-weight-bold">
                        Business Credit Cards
                      </span>{" "}
                      — An unsecured form of credit with lower limits when
                      compared to lines of credit. This type can have high
                      interest rates and fees.
                    </li>
                    <li className="">
                    <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                      <span className="font-weight-bold">Factoring</span> — A
                      company takes on your customer accounts for a fee and then
                      gives you a percentage. This is an expensive option and may
                      lead customers to believe your company does not have the
                      cash it needs.
                    </li>
                    <li className="">
                    <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                      <span className="font-weight-bold">
                        Project Based Financing
                      </span>{" "}
                      — You partner with a stable lending bank that has a home
                      improvement industry focus and can provide support where you
                      need it.
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <HubspotForm
                    portalId="381510"
                    formId="d5ed3b50-8f3b-421d-8209-6509568a5d01"
                    onSubmit={() => this.handleFormSubmit}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
              <p className="short-divider mt-5">
                Here at Regions, we offer project-based financing,
                or what we call “payment options.” As a part of a payment options
                program, you can easily offer your customers loans, with the
                application, underwriting, credit decisions, and funding all
                handled by us. This means you provide a great value-add to your
                customers with minimal lift on your end.
              </p>

              <p className="my-4">
                Many customers don&#39;t want to spend their cash to make repairs and
                renovations in their homes. So, they turn to a variety of
                financing options that allow them pay over a period of time,
                including:
              </p>

              <ul className="fa-ul eb-green">
                <li className="">
                <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Personal loans from traditional banks, credit unions, and online
                  lenders.
                </li>
                <li className="">
                <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Setting a savings goal and setting aside cash over several
                  years. Or they may complete several smaller projects as funds
                  become available, sometimes choosing lower-quality service and
                  materials to make the budget stretch.
                </li>
                <li className="">
                <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Receiving financing from a large home improvement corporation,
                  such as Lowes or Home Depot.
                </li>
                <li className="">
                <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Obtaining a financing plan directly from a contractor they hire
                  to do the work. These plans typically include same-as-cash loans
                  or low monthly payment loans that are paid back over time.
                </li>
              </ul>

              <p className="short-divider mt-5">
                For homeowners, getting financing directly from their contractor
                is generally the most straightforward route. They know they&#39;re
                getting trustworthy financing that works directly with their
                contractor and they&#39;ll be provided with the options they need to
                make an informed decision. Homeowners appreciate the ease and
                convenience of being able to apply for a loan in their home.
              </p>

              <p className="mt-4">
                By working with a trusted lender like Regions,
                contractor financing programs let you offer a variety of payment
                options directly to your customers.
              </p>
          </div>
        </section>
      ) : (
        <section id="overview" className="bg-light-gray">
        <div className="dark-gray py-4">
          <div className="container">
            <h2 className="text-center text-white">
              What Is Contractor Financing?
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="row">
              <span id="join-a-loan-program-now" className=""></span>
              <span id="start-benefitting-now"></span>
              <span id="start-now"></span>
              <span id="start-offering-financing-for-clients"></span>
              <div className="col-md-6">
                <p>
                  <Link
                    to="/contractor-financing/"
                    className="text-blue font-weight-bold"
                  >
                    Contractor financing
                  </Link>{" "}
                  provides an easy way for your customers to pay for their home
                  improvement projects — and for you to get paid quickly and on
                  time. There are several types of financing:
                </p>

                <ul className="mt-4">
                  <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                    <span className="font-weight-bold">
                      Business Lines of Credit
                    </span>{" "}
                    — Flexible funding from a bank or credit union. It can be
                    tapped at any time for the amount needed but has a set
                    limit. You only repay interest on outstanding balances.
                  </li>
                  <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                    <span className="font-weight-bold">
                      Business Credit Cards
                    </span>{" "}
                    — An unsecured form of credit with lower limits when
                    compared to lines of credit. This type can have high
                    interest rates and fees.
                  </li>
                  <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                    <span className="font-weight-bold">Factoring</span> — A
                    company takes on your customer accounts for a fee and then
                    gives you a percentage. This is an expensive option and may
                    lead customers to believe your company does not have the
                    cash it needs.
                  </li>
                  <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                    <span className="font-weight-bold">
                      Project Based Financing
                    </span>{" "}
                    — You partner with a stable lending bank that has a home
                    improvement industry focus and can provide support where you
                    need it.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <HubspotForm
                  portalId="381510"
                  formId="d5ed3b50-8f3b-421d-8209-6509568a5d01"
                  onSubmit={() => this.handleFormSubmit}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
            <p className="short-divider">
              Here at Regions | EnerBank USA, we offer project-based financing,
              or what we call “payment options.” As a part of a payment options
              program, you can easily offer your customers loans, with the
              application, underwriting, credit decisions, and funding all
              handled by us. This means you provide a great value-add to your
              customers with minimal lift on your end.
            </p>

            <p className="my-4">
              Many customers don&#39;t want to spend their cash to make repairs and
              renovations in their homes. So, they turn to a variety of
              financing options that allow them pay over a period of time,
              including:
            </p>

            <ul className="font-weight-bold">
              <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                Personal loans from traditional banks, credit unions, and online
                lenders.
              </li>
              <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                Setting a savings goal and setting aside cash over several
                years. Or they may complete several smaller projects as funds
                become available, sometimes choosing lower-quality service and
                materials to make the budget stretch.
              </li>
              <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                Receiving financing from a large home improvement corporation,
                such as Lowes or Home Depot.
              </li>
              <li className="landscape-why-enerbank-item mb-3 line-height-one-half">
                Obtaining a financing plan directly from a contractor they hire
                to do the work. These plans typically include same-as-cash loans
                or low monthly payment loans that are paid back over time.
              </li>
            </ul>

            <p className="short-divider">
              For homeowners, getting financing directly from their contractor
              is generally the most straightforward route. They know they&#39;re
              getting trustworthy financing that works directly with their
              contractor and they&#39;ll be provided with the options they need to
              make an informed decision. Homeowners appreciate the ease and
              convenience of being able to apply for a loan in their home.
            </p>

            <p className="mt-4">
              By working with a trusted lender like Regions | EnerBank USA,
              contractor financing programs let you offer a variety of payment
              options directly to your customers.
            </p>
          </div>
        </div>
      </section>
      )}
      {isStaging ? (  
      <section
        id="how-contractors-offer-financing-to-customers"
        className="bg-light-gray"
      >
        <div className="">
          <div className="container">
            <h2 className="text-center">
              How do contractors offer financing to their customers?
            </h2>
          </div>
        </div>
        <div className="container pb-5">
          <div className="row py-5">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <img className="image"
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/Contractor_laptop-scaled.jpg"
                alt="offering contractor financing"
              />
            </div>

            <div className="col-lg-5 pl-lg-5">
              <p>
                Contractors can begin offering financing by teaming up with a
                lending company that integrates with the contractor&#39;s existing
                services.
              </p>
              <p className="mt-4">
                These financing plans give your customers something powerful:
                options. Having options means customers can choose the loan that
                works best for their financial situation. More options for
                customers means more business for you, as well as larger, more
                profitable projects.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <h3 className="text-center">
            How Do Contractors Offer Financing to Their Customers?{" "}
          </h3>
          <p className="text-center">
            The Regions process is made simple so you can focus on
            growing your business.
          </p>
        </div>
        <div className="container-lg">
          <div className="col-12 text-center">
            <div className="infographic desktop">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/how-the-process-works-rebrand.png"
                alt="How the process works"
              />
            </div>
            <div className="infographic mobile">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/how-the-process-works-rebrand-mobile.png"
                alt="How the process works"
              />
            </div>
          </div>
        </div>
      </section>
      ) : (
        <section
        id="how-contractors-offer-financing-to-customers"
        className="bg-light-gray"
      >
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              How Do Contractors Offer Financing to Their Customers?
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/Contractor_laptop-scaled.jpg"
                alt="offering contractor financing"
              />
            </div>

            <div className="col-lg-5 pl-lg-5">
              <p>
                Contractors can begin offering financing by teaming up with a
                lending company that integrates with the contractor&#39;s existing
                services.
              </p>
              <p className="mt-4">
                These financing plans give your customers something powerful:
                options. Having options means customers can choose the loan that
                works best for their financial situation. More options for
                customers means more business for you, as well as larger, more
                profitable projects.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <h3 className="text-center">
            How Do Contractors Offer Financing to Their Customers?{" "}
          </h3>
          <p className="text-center">
            The Regions | EnerBank process is made simple so you can focus on
            growing your business.
          </p>
        </div>
        <div className="col-12 text-center py-5">
          <div className="infographic desktop">
            <img
              src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/08/how-the-process-works.png"
              alt="How the process works"
            />
          </div>
          <div className="infographic mobile">
            <img
              src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/08/how-the-process-works-mobile.png"
              alt="How the process works"
            />
          </div>
        </div>
      </section>
      )}

    {isStaging ? (  
      <section id="benefits" className="">
        <div className="">
          <div className="container">
            <h2 className="text-center">
              Why is offering contractor financing for customers beneficial?
            </h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <p className="mb-5 text-center">
                Contractor financing programs are helpful to both customers and
                contractors. Here are the primary benefits.
              </p>
            </div>
          </div>
          </div>
          <div className="container-lg">
          <div className="row icon-cards justify-content-center">
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/bar-graph-trending-up.png" class="" alt="stay ahead of competitors" />
                </div>
                <div className="card-body">
                  <h3 className="pb-3">Staying Ahead of the Competition</h3>
                  <p>Contractors lose jobs when they don't offer financing. Customers will either drop the project once they hear the bid or they&#39;ll seek out a contractor who does offer financing. Stay ahead of the competition by providing options to the customers who need help financing a project that costs more than $5000.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" class="" alt="payment options" />
                </div>
                <div className="card-body">
                  <h3 className="pb-3">Giving Customers Options</h3>
                  <p>Customers appreciate having options. When you approach customers with financing options, you make it easier for them to choose you for your services. Providing convenient options, such as Same-as-Cash loans and low monthly payment loans, puts bigger projects with higher-quality materials within reach for your customers. Having options that work for their individual situation and knowing what options they qualify for makes it easy for them to commit to a project.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/wrench-screwdriver.png" class="" alt="close projects faster" />
                </div>
                <div className="card-body">
                  <h3 className="pb-3">Close Projects Faster</h3>
                  <p>When you offer financing to your customers through EnerBank, you&#39;re more likely to close the project, and can secure funding in one visit.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/flexibility.png" class="" alt="business expansion" />
                </div>
                <div className="card-body">
                  <h3 className="pb-3">Potential for Business Expansion</h3>
                  <p>By empowering your customers to pay for their home improvement projects, you open the door to a greater variety of customers and projects. This leaves space for growing and expanding your business with more projects at a higher price point.</p>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="container pt-5">
            <h3 className="text-center">
              The Benefits of Contractor Financing{" "}
            </h3>
            <p className="text-center mb-5">It goes both ways:</p>
            <div className="row multi-cards justify-content-center pb-5">
              <div className="col-md-5 mb-4">
                <div className="card">
                  <div className="card-image">
                    <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/homeowner-learn-more.jpg"/>
                  </div>
                  <div className="card-body">
                    <h3 className="pb-3">Customer Benefits</h3>
                    <ul className="fa-ul eb-green">
                      <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span> Allows homeowners to<br/> pursue and complete projects</li>
                      <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Provides access to financing options</li>
                      <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Secures funding quickly</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-5 mb-4">
                <div className="card">
                  <div className="card-image">
                    <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/home-improvement.jpg" />
                  </div>
                  <div className="card-body">
                    <h3 className="pb-3">Contractor Benefits</h3>
                    <ul className="fa-ul eb-green">
                      <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Boosts competitive advantage</li>
                      <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Offer better customer service</li>
                      <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Closes home projects faster</li>
                      <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Secure long-term business viability</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col text-center pt-3">
              <button
                onClick={() =>
                  customLink("#start-benefitting-now")}
                className="btn btn-info btn-green"
              >
                Start Benefiting Now
              </button>
            </div>
          </div>
        
      </section>
      ) : (
        <section id="benefits" className="bg-light-gray">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Why Is Offering Contractor Financing for Customers Beneficial?
            </h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <p className="mb-5">
                Contractor financing programs are helpful to both customers and
                contractors. Here are the primary benefits.
              </p>
            </div>
          </div>
          <article>
            <ul>
              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/stay-ahead.png"
                    alt="staying ahead"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Staying Ahead of the Competition{" "}
                  </span>{" "}
                  — Contractors lose jobs when they don&#39;t offer financing.
                  Customers will either drop the project once they hear the bid
                  or they&#39;ll seek out a contractor who does offer financing.
                  Stay ahead of the competition by providing options to the
                  customers who need help financing a project that costs more
                  than $5000.
                </p>
              </li>
              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/multiple-options.png"
                    alt="more options"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Giving Customers Options{" "}
                  </span>{" "}
                  — Customers appreciate having options. When you approach
                  customers with financing options, you make it easier for them
                  to choose you for your services. Providing convenient options,
                  such as Same-as-Cash loans and low monthly payment loans, puts
                  bigger projects with higher-quality materials within reach for
                  your customers. Having options that work for their individual
                  situation and knowing what options they qualify for makes it
                  easy for them to commit to a project.
                </p>
              </li>
              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/fast-project-closure.png"
                    alt="close projects faster"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Close Projects Faster{" "}
                  </span>{" "}
                  — When you offer financing to your customers through EnerBank,
                  you&#39;re more likely to close the project, and can secure
                  funding in one visit.
                </p>
              </li>
              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/business-expansion.png"
                    alt="business expansion"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Potential for Business Expansion{" "}
                  </span>{" "}
                  — By empowering your customers to pay for their home
                  improvement projects, you open the door to a greater variety
                  of customers and projects. This leaves space for growing and
                  expanding your business with more projects at a higher price
                  point.
                </p>
              </li>
            </ul>
          </article>
          <div className="container pt-5">
            <h3 className="text-center">
              The Benefits of Contractor Financing{" "}
            </h3>
            <p className="text-center mb-5">It goes both ways:</p>
            <div className="row justify-content-center">
              <div className="col-md-5 mb-4">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/08/customer-benefits.png"
                  alt="customer benefits"
                />
              </div>
              <div className="col-md-5 mb-4">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/08/contractor-benefits.png"
                  alt="contractor benefits"
                />
              </div>
            </div>
            <div className="col text-center pt-3">
              <Link
                to="#start-benefitting-now"
                className="btn btn-primary font-weight-bold"
              >
                Start Benefitting Now
              </Link>
            </div>
          </div>
        </div>
      </section>
      )}

    {isStaging ? (  
      <section id="how-it-works" className="how-finacning-works bg-light-gray">
          <div className="container">
            <h2 className="text-center">
              How does contractor financing work with Regions?
            </h2>
          </div>

        <div className="container">
          <div className="row pt-5">
            <div className="col">
              <p>
                We developed our tools and technology specifically for the home
                repair and renovation industry. This means you can easily
                integrate offering financing into your existing sales process.
                Generally, here are the steps involved:
              </p>

              <dl className="mt-5 wcf-table-dl">
                <div className="row mr-0 justify-content-center">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    PreQualify
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                  Help your customer discover their purchasing power without a major hit to their credit.<sup>*</sup> This helps you better scope the
                    project and recommend materials.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Loan <br />
                    Application
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    We offer three paperless applications for your customers:
                    Regions Contractor Point of Sale App, Online Loan Application, and Loan-by-Phone.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Credit <br />
                    Decision
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    Customers can receive a credit decision in minutes. If
                    additional documents are needed, the customer can take care
                    of that while you’re still in the home. Once everything is
                    complete, the customer can sign electronically, and the loan
                    is complete.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Request <br />
                    Funds
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    Once the job starts, you can easily request funds through
                    our PartnerPortal system. The customer can approve your
                    request via text message. Funds are then deposited into your
                    account, usually within 24 hours.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Make <br />
                    Payments
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    Customers can make payments on their loan online, over the
                    phone, through the mail, or via overnight delivery. They can
                    also set up recurring payments.
                  </dd>
                </div>
              </dl>
              <p className="font-italic mt-4 pl-4">
                *There&#39;s no hard credit pull during the PreQualify process.
                If the customer chooses to apply for a loan, a hard check will
                take place.
              </p>
            </div>
          </div>
        </div>
      </section>
      ) : (
        <section id="how-it-works" className="bg-light-grey">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              How Does Contractor Financing Work with Regions | EnerBank USA?
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col">
              <p>
                We developed our tools and technology specifically for the home
                repair and renovation industry. This means you can easily
                integrate offering financing into your existing sales process.
                Generally, here are the steps involved:
              </p>

              <dl className="mt-5 wcf-table-dl pl-5">
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    PreQualify
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    Help your customer discover their purchasing power without a
                    major hit to their credit* . This helps you better scope the
                    project and recommend materials.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Loan <br />
                    Application
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    We offer three paperless applications for your customers:
                    Mobile Loan App, Online Loan Application, and Loan-by-Phone.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Credit <br />
                    Decision
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    Customers can receive a credit decision in minutes. If
                    additional documents are needed, the customer can take care
                    of that while you’re still in the home. Once everything is
                    complete, the customer can sign electronically, and the loan
                    is complete.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Request <br />
                    Funds
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    Once the job starts, you can easily request funds through
                    our PartnerPortal system. The customer can approve your
                    request via text message. Funds are then deposited into your
                    account, usually within 24 hours.
                  </dd>
                </div>
                <div className="row mr-0">
                  <dt className="col-lg-3 text-center font-weight-bold p-4 wcf-table-dt">
                    Make <br />
                    Payments
                  </dt>
                  <dd className="col-lg-9 p-4 mb-0 wcf-table-dd">
                    Customers can make payments on their loan online, over the
                    phone, through the mail, or via overnight delivery. They can
                    also set up recurring payments.
                  </dd>
                </div>
              </dl>
              <p className="font-italic mt-4 pl-4">
                *There&#39;s no hard credit pull during the PreQualify process.
                If the customer chooses to apply for a loan, a hard check will
                take place.
              </p>
            </div>
          </div>
        </div>
      </section>
      )}

    {isStaging ? (  
      <section
        id="qualifications-for-contractor-financing"
        className=""
      >
          <div className="container">
            <h2 className="text-center">
              What are the qualifications to offer financing from Regions?
            </h2>
          </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-7 order-lg-2">
              <img
              className="image"
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/contractor-and-clients-scaled.jpg"
                alt="contractor financing qualifications"
              />
            </div>
            <div className="col-lg-5 order-lg-1 mt-5 mt-lg-0">
              <p>
                To{" "}
                <Link to="/sign-up/" className="font-weight-bold">
                  offer payment options
                </Link>{" "}
                from Regions, you must meet the following qualifications:
              </p>
              <ul className="fa-ul eb-green">
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>3+ years in business</li>
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>U.S.-based business</li>
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>$500,000 annual residential sales</li>
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>$5,000 minimum average job size</li>
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Satisfactory business credit report</li>
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Satisfactory BBB report</li>
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Licensed and insured</li>
                <li><span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>Pass financial review</li>
              </ul>
            </div>
          </div>
          <p class="mt-5 text-center text-lg-center mb-0">
            <button
            onClick={() =>
              customLink("#start-now")}
            className="btn btn-info btn-green">
              Start Now
            </button>
          </p>
        </div>
      </section>
    ) : (
      <section
        id="qualifications-for-contractor-financing"
        className="bg-light-gray"
      >
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              What Are the Qualifications to Offer Financing from Regions |
              EnerBank USA?
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-7 order-lg-2">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/contractor-and-clients-scaled.jpg"
                alt="contractor financing qualifications"
              />
            </div>
            <div className="col-lg-5 order-lg-1 mt-5 mt-lg-0">
              <p>
                To{" "}
                <Link to="/sign-up/" className="font-weight-bold">
                  offer payment options
                </Link>{" "}
                from EnerBank, you must meet the following qualifications:
              </p>
              <ul>
                <li className="landscape-why-enerbank-item">
                  3+ years in business
                </li>
                <li className="landscape-why-enerbank-item">
                  U.S.-based business
                </li>
                <li className="landscape-why-enerbank-item">
                  $500,000 annual residential sales
                </li>
                <li className="landscape-why-enerbank-item">
                  $5,000 minimum average job size
                </li>
                <li className="landscape-why-enerbank-item">
                  Satisfactory business credit report
                </li>
                <li className="landscape-why-enerbank-item">
                  Satisfactory BBB report
                </li>
                <li className="landscape-why-enerbank-item">
                  Licensed and insured
                </li>
                <li className="landscape-why-enerbank-item">
                  Pass financial review
                </li>
              </ul>
            </div>
          </div>
          <p class="mt-5 text-center text-lg-center mb-0 pb-5">
            <Link to="#start-now" className="btn btn-primary font-weight-bold">
              Start Now
            </Link>
          </p>
        </div>
      </section>
    )}

    {isStaging ? ( 
      <section id="best-practices" className="bg-light-gray">
          <div className="container">
            <h2 className="text-center">
              Best practices for offering customer financing as a contractor
            </h2>
          </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <p className="mb-5">
                Are you ready to start offering contractor financing to your
                potential customers? Doing so will expand your business and give
                customers more options, but sometimes, it isn&#39;t clear how to
                best offer payment options. As you start offering financing to
                your customers, consider adopting these best practices.
              </p>
            </div>
          </div>

          <article>
            <ul className="fa-ul eb-green ml-5">
              <li className="mb-4 d-flex align-items-start">
                <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="1" /></span>
                <p className="ml-2">
                    <span className="font-weight-bold">
                      Advertise Payment Options{" "}
                    </span>{" "}
                    — Chances are you&#39;re already trying to get your name out
                    there with social media ads, signs around town, newspapers,
                    radio, newsletters, word of mouth, and more. When you start
                    offering contractor financing, make sure it&#39;s mentioned in
                    every ad. It can be as simple as adding &#39;financing
                    available&#39; under your logo or in your advertising.
                  </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
              <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="2" /></span>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Offer Financing to Every Customer{" "}
                  </span>{" "}
                  — Don&#39;t let a conversation with a potential customer go by
                  without mentioning that your offer financing. Emphasize that
                  this can give them the option to expand the size and quality
                  of their projects. Mentioning this option upfront will
                  generate more serious leads.
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
              <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="3" /></span>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Include Financing Options in Bids and Estimates{" "}
                  </span>{" "}
                  — When you provide an estimate for work on a house, let a
                  customer know about their financing options right away.
                  Customers are often overwhelmed by sticker shock when they
                  find out how much money a project is going to cost them. One
                  way to help soften this blow is by providing detailed
                  information about financing and how convenient it is. You
                  don&#39;t want to miss out on a job because customers are
                  hesitant about a price tag when financing is available to help
                  them!
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
              <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="4" /></span>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Understand the Plans You Offer{" "}
                  </span>{" "}
                  — Serious prospects will want to know a little more about your
                  financing options, beyond the fact that they&#39;re available.
                  Read up on your plans and become familiar with your lending
                  company&#39;s offerings. Regions provides training resources and marketing materials so you can understand the financing options and better explain them to your customers. Today&#39;s
                  customers want options, and they want to be informed and
                  educated when they make a decision. Be prepared to discuss
                  plans with them and provide them with the resources they need
                  to make an informed decision.
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
              <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="5" /></span>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Provide the Options Customers Want{" "}
                  </span>{" "}
                  — When offering a variety of options, the likelihood
                  you&#39;ll have the solution that a customer needs increases,
                  making it easier to close the job. Remember that all your
                  customers have different financial needs and there isn&#39;t a
                  “one-size-fits-all” solution when it comes to financing.
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
              <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="6" /></span>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Partner with a Reputable Lending Company{" "}
                  </span>{" "}
                  — A trustworthy lender partner will take care of all the
                  details of financing for you, so you can focus on what you do
                  best. Make sure to carefully research your options and ask
                  questions about any partners you&#39;re considering. Regions
                  is a trustworthy and stable bank that can support both you and
                  your customers. We manage loan application and accounts,
                  providing these services to your customers securely and
                  professionally.
                </p>
              </li>
            </ul>
          </article>
        </div>
      </section>
    ) : (
      <section id="best-practices" className="bg-light-gray">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Best Practices for Offering Customer Financing as a Contractor
            </h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <p className="mb-5">
                Are you ready to start offering contractor financing to your
                potential customers? Doing so will expand your business and give
                customers more options, but sometimes, it isn&#39;t clear how to
                best offer payment options. As you start offering financing to
                your customers, consider adopting these best practices.
              </p>
            </div>
          </div>

          <article>
            <ul>
              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/advertise.png"
                    alt="advertise payment options"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Advertise Payment Options{" "}
                  </span>{" "}
                  — Chances are you&#39;re already trying to get your name out
                  there with social media ads, signs around town, newspapers,
                  radio, newsletters, word of mouth, and more. When you start
                  offering contractor financing, make sure it&#39;s mentioned in
                  every ad. It can be as simple as adding &#39;financing
                  available&#39; under your logo or in your advertising.
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/offer-financing.png"
                    alt="offer financing"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Offer Financing to Every Customer{" "}
                  </span>{" "}
                  — Don’t let a conversation with a potential customer go by
                  without mentioning that your offer financing. Emphasize that
                  this can give them the option to expand the size and quality
                  of their projects. Mentioning this option upfront will
                  generate more serious leads.
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/finanincing-options-bids.png"
                    alt="bids and estimates"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Include Financing Options in Bids and Estimates{" "}
                  </span>{" "}
                  — When you provide an estimate for work on a house, let a
                  customer know about their financing options right away.
                  Customers are often overwhelmed by sticker shock when they
                  find out how much money a project is going to cost them. One
                  way to help soften this blow is by providing detailed
                  information about financing and how convenient it is. You
                  don&#39;t want to miss out on a job because customers are
                  hesitant about a price tag when financing is available to help
                  them!
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/plans.png"
                    alt="various plans"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Understand the Plans You Offer{" "}
                  </span>{" "}
                  — Serious prospects will want to know a little more about your
                  financing options, beyond the fact that they&#39;re available.
                  Read up on your plans and become familiar with your lending
                  company&#39;s offerings. EnerBank provides training resources
                  and marketing materials so you can understand the financing
                  options and better explain them to your customers. Today&#39;s
                  customers want options, and they want to be informed and
                  educated when they make a decision. Be prepared to discuss
                  plans with them and provide them with the resources they need
                  to make an informed decision.
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/desired-options.png"
                    alt="best options"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Provide the Options Customers Want{" "}
                  </span>{" "}
                  — When offering a variety of options, the likelihood
                  you&#39;ll have the solution that a customer needs increases,
                  making it easier to close the job. Remember that all your
                  customers have different financial needs and there isn&#39;t a
                  “one-size-fits-all” solution when it comes to financing.
                </p>
              </li>

              <li className="mb-4 d-flex align-items-start">
                <div className="wcf-img-li-box mr-3">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/partnering.png"
                    alt="partnership"
                  />
                </div>
                <p className="ml-2">
                  <span className="font-weight-bold">
                    Partner with a Reputable Lending Company{" "}
                  </span>{" "}
                  — A trustworthy lender partner will take care of all the
                  details of financing for you, so you can focus on what you do
                  best. Make sure to carefully research your options and ask
                  questions about any partners you&#39;re considering. EnerBank
                  is a trustworthy and stable bank that can support both you and
                  your customers. We manage loan application and accounts,
                  providing these services to your customers securely and
                  professionally.
                </p>
              </li>
            </ul>
          </article>
        </div>
      </section>
    )}

    {isStaging ? ( 
      <section id="join-a-loan-program" className="">
          <div className="container">
            <h2 className="text-center">
              Join a loan program with Regions
            </h2>
          </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-7">
              <img className="image"
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/roadside.jpg"
                alt="join a loan program"
              />
            </div>
            <div className="col-lg-5 pl-4 mt-5 mt-lg-0">
              <p>
                Your customers deserve options, and you deserve the chance to
                grow and gain an edge over the competition. For a great market
                advantage, start offering Regions payment options to your
                customers. We&#39;re available to contractors and customers
                across the United States.
              </p>
              <p className="mt-4">
                Get started today with the products, tools, and resources you
                need to grow your business by offering your customers financing
                options.
              </p>
              <p class="mt-5 text-center text-lg-left">
                <button
                  onClick={() =>
                    customLink("#join-a-loan-program-now")}
                  className="btn btn-info btn-green"
                >
                  Join a loan program now!
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    ) : (
    <section id="join-a-loan-program" className="bg-light-gray">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Join a Loan Program with Regions | EnerBank USA
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-7">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/roadside.jpg"
                alt="join a loan program"
              />
            </div>
            <div className="col-lg-5 pl-4 mt-5 mt-lg-0">
              <p>
                Your customers deserve options, and you deserve the chance to
                grow and gain an edge over the competition. For a great market
                advantage, start offering EnerBank payment options to your
                customers. We&#39;re available to contractors and customers
                across the United States.
              </p>
              <p className="mt-4">
                Get started today with the products, tools, and resources you
                need to grow your business by offering your customers financing
                options.
              </p>
              <p class="mt-5 text-center text-lg-left">
                <Link
                  to="#join-a-loan-program-now"
                  className="btn btn-primary font-weight-bold"
                >
                  Join a loan program now!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    )}

    {isStaging ? ( 
      <section id="faqs" className="bg-light-gray">
          <div className="container">
            <h2 className="text-center pb-5">
              Frequently asked questions
            </h2>
          </div>
          <div className="container">
          <div className="row">
            <div className="col-md-12">
            <Tabbordion
              blockElements={blockElements}
              animateContent={"height"}
              mode={"toggle"}
              className="regions-accordion"
              name="accordion"
          >
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">What kinds of projects can I use financing for?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                        From the foundation all the way up to the roof — inside, outside, large, or small — our loans are tailored to meet hundreds of improvement types. Contact us to discover which loans will best suit your business model.</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">
                              Will offering financing delay my sales process by adding a step?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>          
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                        No. Though it may seem counterintuitive, adding financing can actually speed things up. Our goal is to help you reorganize your process when you offer financing, not slow it down. Our paperless application methods and quick credit decisions mean the customer can be approved for a loan while you're still in the home
                      </p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">
                              What's the difference between secured and unsecured loans?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      Secured loans require collateral while unsecured loans don't. Unlike secured loans, a homeowner can obtain an unsecured loan quickly and without having to risk their real property or add a lien on their home. Customers with a wide range of credit scores can receive approval for these types of loans. Regions loans are unsecured.</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">Am I responsible if my customer doesn't make their loan payments</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      Regions loans are all non-recourse for you, the contractor. That means we assume all the credit risk. We'll never pull funds back from you if a customer is delinquent or defaults on their loan</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className=" btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">
                              Are there other ways for my customers to apply for a loan?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                        Yes. You can add our convenient banners and buttons to your website and marketing materials. Homeowners will then be able to use PreQualify or apply for a loan on their own time.</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className=" btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">
                              Who can I talk to if I have questions about my loan program?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      Upon joining, you'll be assigned your very own relationship manager. They'll act as your strategic consultant and finance coach, helping you experience the full benefits that payment options provide.</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className=" btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">
                              How do I manage my loan program?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      As a part of your program, you'll have access to <a href="https://partnerportalapp.enerbank.com/" target="_blank" rel="noreferrer noopener" class="text-blue">PartnerPortal</a>, your one-stop shop for tracking and managing your customer's loans. There's both a desktop and mobile version, and you can download it for free from the <a href="https://apps.apple.com/us/app/enerbank-usa-partner-portal/id1501254896" target="_blank" rel="noopener noreferrer" class="text-blue">App Store</a> or <a href="https://play.google.com/store/apps/details?id=com.enerbank.partnerportal" target="_blank" rel="noopener noreferrer" class="text-blue">Google Play</a>.</p>
                  </TabContent>
              </TabPanel>
          </Tabbordion>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <section id="faqs" className="bg-light-gray">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="py-5">
            <dl>
              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    What kinds of projects can I use financing for?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    From the foundation all the way up to the roof — inside,
                    outside, large, or small — our loans are tailored to meet
                    hundreds of improvement types. Contact us to discover which
                    loans will best suit your business model.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    Will offering financing delay my sales process by adding a
                    step?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    No. Though it may seem counterintuitive, adding financing
                    can actually speed things up. Our goal is to help you
                    reorganize your process when you offer financing, not slow
                    it down. Our paperless application methods and quick credit
                    decisions mean the customer can be approved for a loan while
                    you&#39;re still in the home.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    What&#39;s the difference between secured and unsecured
                    loans?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    Secured loans require collateral while unsecured loans
                    don&#39;t. Unlike secured loans, a homeowner can obtain an
                    unsecured loan quickly and without having to risk their real
                    property or add a lien on their home. Customers with a wide
                    range of credit scores can receive approval for these types
                    of loans. EnerBank loans are unsecured.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    Am I responsible if my customer doesn&#39;t make their loan
                    payments?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    EnerBank loans are all non-recourse for you, the contractor.
                    That means we assume all the credit risk. We&#39;ll never
                    pull funds back from you if a customer is delinquent or
                    defaults on their loan.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    Are there other ways for my customers to apply for a loan?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    Yes. You can add our convenient banners and buttons to your
                    website and marketing materials. Homeowners will then be
                    able to use PreQualify or apply for a loan on their own
                    time.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    Who can I talk to if I have questions about my loan program?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    Upon joining, you&#39;ll be assigned your very own
                    relationship manager. They&#39;ll act as your strategic
                    consultant and finance coach, helping you experience the
                    full benefits that payment options provide.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">How do I manage my loan program?</span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    As a part of your program, you&#39;ll have access to{" "}
                    <a
                      href="https://partnerportalapp.enerbank.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="text-blue"
                    >
                      PartnerPortal
                    </a>
                    , your one-stop shop for tracking and managing your
                    customer&#39;s loans. There&#39;s both a desktop and mobile
                    version, and you can download it for free from the{" "}
                    <a
                      href="https://apps.apple.com/us/app/enerbank-usa-partner-portal/id1501254896"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue"
                    >
                      App Store
                    </a>{" "}
                    or{" "}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.enerbank.partnerportal"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue"
                    >
                      Google Play
                    </a>
                    .
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
    )}
    </Layout>
  )
}

export default WhatIsContractorFinancingPage
